import { Col, Image, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import bill from "../../../../assets/images/logo6-small.png";

const SideBarHeader = () => {
  const {logo } = useSelector((state) => state.auth);
  return (
    <Row>
      <Col
        lg={12}
        className="d-flex justify-content-center py-2"
        style={{
          //boxShadow: "1.0px 0.5px 7.0px hsl(0deg 0% 0% / 0.38)",
          border: "1px solid lightgray",
        }}
      >
        <Image src={logo || bill} alt="Bill Now"  height={50} />
      </Col>
    </Row>
  );
};

export default SideBarHeader;
