import React, { useEffect, useRef, useState } from "react";
import bill from "../../assets/images/logo6-small.png";
import {
  Row,
  Col,
  Button,
  Image,
  InputGroup,
  FormControl,
  Container,
  Form,
} from "react-bootstrap";
import OTPInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { addOtp, addPhoneNumber, getOtp, otpVerify, resetAuth } from "./store/authSlice";
import { useNavigate } from "react-router-dom";




const LoginScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { phone, isOtpReceivedStatus, otp, accessToken, isLoggedIn, tenantId, errorStatus, errorMessage, otpErrorStatus, otpErrorMessage, logo, businessname, businessaddres } = useSelector((state) => state.auth)
  const [phoneError, setPhoneError] = useState("");
  const [otpError, setOtpError] = useState("");
  const [timer, setTimer] = useState(0);
  const [canResend, setCanResend] = useState(false);
  const [verify, setVerify] = useState(false);
  // const [getOtp, setGetOtp] = useState(false);



  const otpInputRef = useRef(null);

  useEffect(() => {
    dispatch(resetAuth())
  }, [])

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/home')
    }
  }, [isLoggedIn])

  const handleInputChange = (event) => {
    setPhoneError('')
    const inputValue = event.target.value.replace(/\D/g, "");
    dispatch(addPhoneNumber(inputValue))

  };



  const validation = () => {
    let valid = false
    if (!phone) {
      setPhoneError('please enter the phone')
      return valid
    }
    else if (isOtpReceivedStatus && !otp) {
      setOtpError('please enter the otp')
      return valid
    }
    else {
      return valid = true
    }


  }


  const handleGetOtp = () => {
    const valid = validation()
    if (valid) {
      console.log('get otp service started')
      setTimer(120);
      setVerify(true);
      setCanResend(false);
      dispatch(getOtp({ phone: phone, tenantId: tenantId }))

    }
  }




  const handleOtpChange = (otpvalue) => {
    setOtpError("");
    dispatch(addOtp(otpvalue))

  }

  useEffect(() => {
    if (timer > 0) {
      const countdown = setTimeout(() => setTimer(timer - 1), 1000);
      return () => clearTimeout(countdown);
    } else if (timer === 0) {
      setCanResend(true);
      setVerify(false);
    }
  }, [timer]);

  const handleVerify = () => {
    const valid = validation()
    if (valid) {
      setVerify(false);
      dispatch(otpVerify({ phone: phone, otp: otp, tenantId: tenantId }))
    }


  };



  return (
    <Container
      className="d-flex flex-column justify-content-center"
      style={{ height: "100vh", width: "100%" }}
    >
      <Row className="d-flex justify-content-center">
        <Col xs={12} lg={5} className="d-flex flex-column gap-3">
          <Col className=" d-flex justify-content-center ">
            <Image src={logo || bill} alt="Bill Now" style={{ height: "50px" }} />
          </Col>
          <Col className="d-flex flex-column align-items-center text-center">
            <div className="d-flex align-items-center">
              <h6 className="mb-0 me-2">Business Name:</h6>
              <p className="mb-0">{businessname}</p>
            </div>
          </Col>
          <Col className="d-flex flex-column align-items-center text-center">
            <div className="d-flex align-items-center">
              <h6 className="mb-0 me-2">Business Address:</h6>
              <p className="mb-0">{businessaddres}</p>
            </div>
          </Col>
          <Row className="mt-4 justify-content-center">
            <Col lg={7}>
              <label className="form-label">{"Enter your phone number"}</label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="phone-prefix">+91</InputGroup.Text>
                <FormControl
                  type="text"
                  name="phone"
                  pattern="[0-9]*"
                  minLength={10}
                  maxLength={10}
                  placeholder={"Please enter your phone number"}
                  onChange={handleInputChange}
                  value={phone}
                  autoFocus={true}
                />
              </InputGroup>
              {errorStatus && <div className="text-danger">{errorMessage}</div>}
            </Col>

          </Row>
          {isOtpReceivedStatus &&
            verify && (
              <Col>
                <label className="form-label">
                  {"Enter OTP sent to your phone"}
                </label>
                <OTPInput
                  ref={otpInputRef}
                  value={otp}
                  onChange={handleOtpChange}
                  numInputs={6}
                  renderInput={(props) => <input {...props} />}
                  //renderSeparator={<span></span>}
                  inputStyle={{
                    width: "100%",
                    marginLeft: "5px",
                    height: "40px",
                    border: "1px solid lightgray",
                    borderRadius: "4px",
                  }}
                  shouldAutoFocus={true}
                />
                {otpErrorStatus && (
                  <div className="text-danger">{otpErrorMessage}</div>
                )}
              </Col>
            )}
          <Row className="justify-content-center">
            <Col lg={6} className="d-flex flex-column align-items-center">
              {verify ? (
                <>
                  <Button className="w-50" onClick={handleVerify}>
                    Verify
                  </Button>
                  <Form.Label className="mx-5 mt-2">
                    Resend OTP : {timer} Sec
                  </Form.Label>
                </>
              ) : (
                <Button className="w-50" onClick={handleGetOtp}>
                  {/* {canResend ? "Resend OTP" : "Get OTP"} */}
                  Login
                </Button>
              )}
            </Col>
          </Row>
        </Col>
        {/* {verify && isOtpReceivedStatus ? (
          <Col className="col-lg-3 d-flex align-items-center">
            <Button className="w-50" onClick={handleVerify}>
              Verify{" "}
            </Button>
            <Form.Label className="mx-5 mt-2">
              Resend OTP : {timer} Sec
            </Form.Label>
          </Col>
        )} */}
      </Row>
      <Row className="d-flex justify-content-center align-items-center text-center">
        <Col>
          <div style={{
            fontSize: '12px',
            padding: '80px'
          }}>
            <div>Powered By</div>
            <div>
              <Image src={bill} alt="Bill Now" style={{ height: "30px" }} />
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginScreen;
